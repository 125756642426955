import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  currentUser: any = {};
  currentMenu: any = {};

  constructor(
    private _storage: StorageService,
    private _crud: CrudService
  ) {
    if (this._storage.get('data_sesion')) {
      this.currentUser = this._storage.get('data_sesion').usuario;
      this.currentMenu = this._storage.get('data_sesion').menu;
    }
  }

  getData(): any {
    return this._storage.get('data_sesion').usuario;
  }

  getMenu(): any {
    return this._storage.get('data_sesion').menu.opciones;
  }

  changeData(usuario:any) {
    this.currentUser = usuario;
    let data_sesion = this._storage.get('data_sesion');
    data_sesion.usuario = usuario;
    this._storage.set('data_sesion', data_sesion);
  }

  reloadMenu()
  {
    this._crud.get('auth/refresh_menu')
    .then((res) => {
      this.currentMenu = res;
      let data_sesion = this._storage.get('data_sesion');
      data_sesion.menu = res;
      this._storage.set('data_sesion', data_sesion);
      location.reload();
    })
    .catch((error) => {
      console.error(error.error.message);
    });
  }

  getPermisos(): Array<any> {
    return (this._storage.get('data_sesion')) ? this._storage.get('data_sesion').permisos : [];
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { UsuarioService } from './usuario.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http:HttpClient,
    private router:Router,
    private _storage: StorageService,
    private _user: UsuarioService
  ) { }

  login(usuario : any) : Promise<any>
  {
    return this.http.post(environment.URL_SERVER + 'auth/login',usuario).toPromise()
    .then(r => {
      let res:any = Object.assign({}, r);

      this._storage.set('token', res.token);
      this._storage.set('data_sesion', res.data_sesion);
      this._user.currentUser = this._storage.get('data_sesion').usuario;
      this._user.currentMenu = this._storage.get('data_sesion').menu;
      this.router.navigate(['admin/dashboard']);

      return Promise.resolve(r);
    })
    .catch(error => {
      return Promise.reject(error);
    });
  }

  logout(): Promise<any>
  {
    return this.http.get(environment.URL_SERVER + 'auth/logout').toPromise()
      .then(res => {
        this._storage.destroyAll();
        this.router.navigate(['/auth/login']);
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  getToken():string
  {
    return this._storage.get('token');
  }

  refreshToken(): Promise<any>
  {
    return this.http.get(environment.URL_SERVER + 'auth/refresh').toPromise()
    .then(r => {
      let res: any = Object.assign({}, r);
      this._storage.set('token', res.token);
      return Promise.resolve(res);
    })
    .catch(error => {
      return Promise.reject(error);
    });
  }

  check() : boolean
  {
    return (this.getToken() && this.getToken().length >= 300) ? true : false;
  }

}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import { tap } from 'rxjs/operators';
import { Route, Router } from '@angular/router';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private _auth: AuthService,
        private _storage: StorageService,
        private router : Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let url = req.url.split('/');

        if (url[url.length - 1] !== 'login') {

            const token = this._auth.getToken();

            const headers = new HttpHeaders({
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
            });
            const newRequest = req.clone({ headers: headers });
            return next.handle(newRequest).pipe(tap(
                res => {

                },
                error => {
                    if(error.status === 401)
                    {
                        this._storage.destroyAll();
                        this.router.navigate(['auth/login']);
                    }
                }
            ));
        }
        return next.handle(req);
    }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(
    private http: HttpClient
  ) { }

  list(route: string): Promise<any> {
    return this.http.get(environment.URL_SERVER + route + '/index').toPromise()
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  show(route: string, id: number): Promise<any> {
    return this.http.get(environment.URL_SERVER + route + '/show/' + id).toPromise()
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  save(route: string, data: any): Promise<any> {
    return this.http.post(environment.URL_SERVER + route + '/store', data).toPromise()
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  edit(route: string, data: any): Promise<any> {
    return this.http.post(environment.URL_SERVER + route + '/update/' + data.id, data).toPromise()
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  delete(route: string, data: any): Promise<any> {
    return this.http.delete(environment.URL_SERVER + route + '/destroy/' + data.id).toPromise()
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  get(route: string): Promise<any> {
    return this.http.get(environment.URL_SERVER + route).toPromise()
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  post(route: string, data: any): Promise<any> {
    return this.http.post(environment.URL_SERVER + route, data).toPromise()
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  set(name: any, data: any) {
    sessionStorage.setItem(window.btoa(name), window.btoa(JSON.stringify(data)));
  };

  get(name: any) {
    let variable = sessionStorage.getItem(window.btoa(name));
    if (variable) {
      return JSON.parse(window.atob(variable));
    }
  };

  destroy(name: any) {
    return sessionStorage.removeItem(name);
  };

  getAll() {
    return sessionStorage;
  };

  destroyAll() {
    this.getAll().clear();
  }

}
